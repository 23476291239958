/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import ScreenHeight from "~components/ScreenHeight";
import SEO from "~components/SEO";
import Video from "~components/Video";
import { getRandomIntByRange, parseProjects } from "~utils/helpers";

const WorkPage = ({ data, location }) => {
  const { setHeaderColor, workLink } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const [hoverBackground, setHoverBackground] = useState(`#f9f9f9`);
  const [hoverFont, setHoverFont] = useState(`#000000`);
  const [type, setType] = useState(`Recent Projects`);

  const { frontmatter } = data.markdownRemark;
  const { archives, projects } = parseProjects(data);

  //

  useEffect(() => {
    if (!type) {
      return;
    }

    switch (type.toLowerCase()) {
      case `playground`:
        setHoverBackground(`#000000`);
        setHeaderColor(`white`);
        break;

      default:
        setHoverBackground(`#f9f9f9`);
        setHeaderColor(`black`);
        break;
    }
  }, [type]);

  useEffect(() => {
    if (!workLink) {
      return;
    }

    setType(workLink);
  }, [workLink]);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout
        className="work-page w-full relative pt-v10 xs:pt-v20 pb-v10 bg-antiflash"
        style={{
          backgroundColor: hoverBackground,
          color: hoverFont
        }}
      >
        <div className="grid">
          <div className="grid-end-24 grid-start-1 animation-appear-down animation-delay-1 flex xs:justify-between xs:pt-v6 pb-v3 xs:pb-v10">
            <CursorHoverable>
              <button
                type="button"
                className={`${type === `Recent Projects` ? `text-blue` : ``} ${
                  type === `Playground` ? `text-white` : ``
                } work-page__switcher transition-color hover-underline mr-v3 ${
                  device === `desktop` ? `f4` : `b1`
                }`}
                onClick={() => setType(`Recent Projects`)}
              >
                Recent Projects
              </button>
            </CursorHoverable>

            <CursorHoverable>
              <button
                type="button"
                className={`${
                  type === `Playground` ? `text-blue` : ``
                } work-page__switcher transition-color hover-underline mr-v3 ${
                  device === `desktop` ? `f4` : `b1`
                }`}
                onClick={() => setType(`Playground`)}
              >
                Playground
              </button>
            </CursorHoverable>

            <CursorHoverable>
              <button
                type="button"
                className={`${type === `Archive` ? `text-blue` : ``} ${
                  type === `Playground` ? `text-white` : ``
                } work-page__switcher transition-color hover-underline mr-v3 ${
                  device === `desktop` ? `f4` : `b1`
                }`}
                onClick={() => setType(`Archive`)}
              >
                Archive
              </button>
            </CursorHoverable>
          </div>
        </div>

        <div className="grid">
          {type === `Recent Projects` &&
            projects.map(project => {
              let mediaJSX;

              if (device && device === `desktop`) {
                if (
                  project?.frontmatter?.workVideoURL &&
                  project.frontmatter.workVideoURL !== ``
                ) {
                  mediaJSX = (
                    <Video
                      className="w-full h-full relative block object-cover"
                      src={project.frontmatter.workVideoURL}
                      controls={project.frontmatter.workVideoControls || false}
                    />
                  );
                } else if (
                  project?.frontmatter?.workImage?.childImageSharp?.fluid
                ) {
                  mediaJSX = (
                    <Img
                      className="w-full h-full relative block object-cover"
                      fluid={
                        project.frontmatter.workImage.childImageSharp.fluid
                      }
                      alt={project.frontmatter.workImageAlt}
                    />
                  );
                } else if (project?.frontmatter?.workImage?.publicURL) {
                  mediaJSX = (
                    <img
                      className="w-full h-full relative block object-cover"
                      src={project.frontmatter.workImage.publicURL}
                      alt={project.frontmatter.workImageAlt}
                    />
                  );
                }
              } else if (
                project?.frontmatter?.workVideoURLXS &&
                project.frontmatter.workVideoURLXS !== ``
              ) {
                mediaJSX = (
                  <Video
                    className="w-full h-full relative block object-cover"
                    src={project.frontmatter.workVideoURLXS}
                    controls={project.frontmatter.workVideoControls || false}
                  />
                );
              } else if (
                project?.frontmatter?.workImageXS?.childImageSharp?.fluid
              ) {
                mediaJSX = (
                  <Img
                    className="w-full h-full relative block object-cover"
                    fluid={
                      project.frontmatter.workImageXS.childImageSharp.fluid
                    }
                    alt={project.frontmatter.workImageAlt}
                  />
                );
              } else if (project?.frontmatter?.workImageXS?.publicURL) {
                mediaJSX = (
                  <img
                    className="w-full h-full relative block object-cover"
                    src={project.frontmatter.workImageXS.publicURL}
                    alt={project.frontmatter.workImageAlt}
                  />
                );
              }

              //

              const innerJSX = (
                <Link
                  to={project.fields.slug}
                  className="w-full h-full absolute top-0 right-0 bottom-0 left-0"
                >
                  {mediaJSX && mediaJSX}
                </Link>
              );

              //

              return (
                <section className="work-page__item relative grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1">
                  <div
                    key={project.fields.slug}
                    className="work-page__item__inner animation-appear-down animation-delay-2 sixteen-by-nine--padded relative overflow-hidden"
                    onMouseEnter={() => {
                      if (device !== `desktop`) {
                        return;
                      }

                      setHoverBackground(project.frontmatter.backgroundColor);
                      setHoverFont(
                        project.frontmatter.hoverFontColor || `#000000`
                      );
                    }}
                    onMouseLeave={() => {
                      setHoverBackground(`#f9f9f9`);
                      setHoverFont(`#000000`);
                    }}
                  >
                    {device && device === `desktop` && (
                      <CursorHoverable>{innerJSX}</CursorHoverable>
                    )}

                    {device && device !== `desktop` && (
                      <ScreenHeight
                        className="relative overflow-hidden"
                        scale={0.8}
                      >
                        {innerJSX}
                      </ScreenHeight>
                    )}
                  </div>

                  <div className="work-page__item__text w-full relative xs:block flex items-center justify-between mt-v1 xs:mt-v3 mb-v6 xs:mb-v12">
                    <h3 className="f5">{project.frontmatter.title}</h3>

                    <CursorHoverable>
                      <Link to={project.fields.slug}>
                        <h3 className="xs:mt-v2 b1 underline">View Project</h3>
                      </Link>
                    </CursorHoverable>
                  </div>
                </section>
              );
            })}
        </div>

        {type === `Playground` && (
          <>
            <div className="grid">
              <h2 className="grid-end-24 mb-v6 f5 text-white">
                A place for our experiments and happy accidents.
              </h2>
            </div>

            <div className="grid">
              <ul className="grid-end-24">
                {frontmatter.playground.map(({ row }, playgroundItemIndex) => {
                  return (
                    <li className="work-page__playground-row relative flex xs:flex-wrap justify-between">
                      {row.map(rowItem => {
                        const {
                          caption,
                          columnWidth,
                          image,
                          imageAlt,
                          videoURL,
                          videoControls
                        } = rowItem;

                        let mediaJSX;

                        if (videoURL && videoURL !== ``) {
                          mediaJSX = (
                            <Video
                              className="w-full relative block"
                              src={videoURL}
                              controls={videoControls || false}
                            />
                          );
                        } else if (image?.childImageSharp?.fluid) {
                          mediaJSX = (
                            <Img
                              className="w-full relative block"
                              fluid={image.childImageSharp.fluid}
                              alt={imageAlt}
                            />
                          );
                        } else if (image?.publicURL) {
                          mediaJSX = (
                            <img
                              className="w-full relative block"
                              src={image.publicURL}
                              alt={imageAlt}
                            />
                          );
                        }

                        //

                        return (
                          <div
                            className={`work-page__playground-row__item grid-width-${columnWidth} animation-appear-down animation-delay-2 relative mb-v8 xs:mb-v12`}
                          >
                            {mediaJSX && mediaJSX}

                            {caption && caption !== `` && (
                              <p className="mt-v1 xs:mt-v3 b1 text-white">
                                {caption}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}

        {type === `Archive` && (
          <div className="grid">
            <ul className="grid-end-24 mt-v3 xs:mb-v8">
              {archives.map((archiveItem, archiveItemIndex) => {
                let mediaJSX;

                if (device && device === `desktop`) {
                  if (
                    archiveItem?.frontmatter?.workVideoURL &&
                    archiveItem.frontmatter.workVideoURL !== ``
                  ) {
                    mediaJSX = (
                      <Video
                        className="w-full h-full relative block object-cover"
                        src={archiveItem.frontmatter.workVideoURL}
                        controls={
                          archiveItem.frontmatter.workVideoControls || false
                        }
                      />
                    );
                  } else if (
                    archiveItem?.frontmatter?.workImage?.childImageSharp?.fluid
                  ) {
                    mediaJSX = (
                      <Img
                        className="w-full h-full relative block object-cover"
                        fluid={
                          archiveItem.frontmatter.workImage.childImageSharp
                            .fluid
                        }
                        alt={archiveItem.frontmatter.workImageAlt}
                      />
                    );
                  } else if (archiveItem?.frontmatter?.workImage?.publicURL) {
                    mediaJSX = (
                      <img
                        className="w-full h-full relative block object-cover"
                        src={archiveItem.frontmatter.workImage.publicURL}
                        alt={archiveItem.frontmatter.workImageAlt}
                      />
                    );
                  }
                }

                if (!archiveItem.rightMargin) {
                  archiveItem.rightMargin = getRandomIntByRange(-5, 25);
                }

                return (
                  <li
                    className="work-page__archive-item animation-appear-down w-full relative mb-v2"
                    style={{ animationDelay: `${archiveItemIndex * 100}ms` }}
                  >
                    <CursorHoverable>
                      <Link to={archiveItem.fields.slug}>
                        <div className="w-full relative xs:pt-v4 xs:pb-v4 flex xs:items-center xs:justify-between">
                          <h2
                            className={`xs:order-1 mr-v3 xs:mr-1 ${
                              device === `desktop` ? `f5` : `b1`
                            }`}
                            style={{ marginTop: `0.5vw` }}
                          >
                            {archiveItem.frontmatter.date}
                          </h2>

                          <h3
                            className={`hover-underline xs:pr-3 ${
                              device === `desktop` ? `f3` : `f5`
                            }`}
                          >
                            {archiveItem.frontmatter.title}
                          </h3>
                        </div>

                        <div
                          className="work-page__archive-item__image desktop-only w-1/3 absolute pointer-events-none"
                          style={{ right: `${archiveItem.rightMargin}%` }}
                        >
                          <div className="work-page__archive-item__text w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center">
                            <Link to={archiveItem.fields.slug}>
                              <h3 className="f5 text-white">View Project</h3>
                            </Link>
                          </div>

                          <figure className="w-full h-full relative gpu overflow-hidden">
                            {mediaJSX && mediaJSX}
                          </figure>
                        </div>
                      </Link>
                    </CursorHoverable>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </Layout>

      <Footer />
    </>
  );
};

export default WorkPage;

export const query = graphql`
  query WorkPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        playground {
          row {
            columnWidth
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            imageAlt
            videoURL
            videoControls
          }
        }
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "case-study-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            priority
            date
            backgroundColor
            hoverFontColor
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            featuredImageXS {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            featuredImageAlt
            featuredVideoURL
            featuredVideoURLXS
            featuredVideoControls
            workImage {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            workImageXS {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            workImageAlt
            workVideoURL
            workVideoURLXS
            workVideoControls
          }
        }
      }
    }
  }
`;
